'use strict'

/**
 * Gets the Cross-Browser compatible scroll y value.
 * @returns {Number} The scroll y value.
 */
function getWindowScrollY() {
    if (undefined !== window.scrollY) {
        return window.scrollY
    }
    if (undefined !== pageYOffset) {
        return pageYOffset
    }

    if (undefined !== document.documentElement.scrollTop) {
        return document.documentElement.scrollTop
    }

    return 0
}

/**
 * Determines if an element is visible by the attributes it possesses.
 * @param {HTMLElement} element An HTML element on the page.
 * @returns {boolean} True if the element is visible, else false.
 */
function isVisibleByDisplay(element) {
    return 0 < element.offsetWidth
        && 0 < element.offsetHeight
}

/**
 * Determines if an element is (nearly) visible by the screen position.
 * @param {HTMLElement} element An HTML element on the page.
 * @param {number} scaleFactor An optional parameter that determines how "close" the element has to be to be considered visible.
 * @returns {boolean} True if the element is nearly visible, else false.
 */
function isVisibleByScreenPosition(element, scaleFactor) {
    return element.getBoundingClientRect().top <= (getWindowScrollY() + ((scaleFactor || 1) * window.innerHeight))
}

/**
 * Lazy loads any images present on the website.
 */
function lazyLoadImage() {
    const lazyload = document.getElementsByClassName("lazyload")

    for (let i = lazyload.length - 1; 0 <= i; --i) {
        // @ts-ignore
        if (!lazyload[i].dataset.src) {
            continue;
        }
        // @ts-ignore
        if (isVisibleByScreenPosition(lazyload[i], 1.5) && isVisibleByDisplay(lazyload[i])) {
            // @ts-ignore
            lazyload[i].src = lazyload[i].dataset.src
            lazyload[i].className = lazyload[i].className.replace(/lazyload/g, '')
        }
    }
}

// @ts-ignore
window.addEventListener("touchmove", lazyLoadImage, Modernizr.passiveeventlisteners ? { passive: true } : false)
// @ts-ignore
window.addEventListener("scroll", lazyLoadImage, Modernizr.passiveeventlisteners ? { passive: true } : false)
// @ts-ignore
window.addEventListener("load", lazyLoadImage, Modernizr.passiveeventlisteners ? { passive: true } : false)
// @ts-ignore
window.addEventListener("resize", lazyLoadImage, Modernizr.passiveeventlisteners ? { passive: true } : false)